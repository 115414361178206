
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { AdminUser } from '@/store/modules/members/AdminsModule';
  import { useAbility } from '@casl/vue';
  import Multiselect from '@vueform/multiselect';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'admins-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const relatedToHub = ref(false);

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('adminUsersUpdating'), [
          translate('members'),
        ]);

        await store.dispatch(Actions.GET_ALL_ROLES, { limit: 50 });
      });

      const { data } = await store.dispatch(
        Actions.GET_ADMIN_USER,
        route.params.id
      );

      const admin = ref<AdminUser>(data.data);

      if (admin.value.adminHubs.length > 0) {
        relatedToHub.value = true;
      }

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const hubIds = admin.value.adminHubs?.map((i) => i.id);

      const selectedHubs = ref<Array<string>>(hubIds);

      const goBack = () => {
        router.back();
      };

      const adminMobile = computed(() => {
        const mobileString = String(admin.value.mobile);
        const last7Digits = mobileString.slice(-10);
        return String(last7Digits);
      });

      const hubs = await store.dispatch(Actions.GET_ALL_HUBS_CREATING);

      const hubsTitle = () => {
        return hubs.map((r) => {
          return { title: r.titleTranslate, id: r.id };
        });
      };

      const adminSchema = Yup.object().shape({
        name: Yup.string()
          .required(() => translate('FULL_NAME_IS_REQUIRED_FIELD'))
          .label('Name'),
        email: Yup.string()
          .email(() => translate('EMAIL_NOT_VALID'))
          .required(() => translate('EMAIL_IS_REQUIRED_FIELD'))
          .label('Email'),
        username: Yup.string()
          .required(() => translate('EMAIL_IS_REQUIRED_FIELD'))
          .label('Email'),
        mobile: Yup.string()
          .matches(/^(77|78|75)\d{8}$/, 'Mobile number is not valid')
          .required(() => translate('PHONE_IS_REQUIRED_FIELD'))
          .label('Mobile'),
        roleId: Yup.string()
          .notOneOf(['0'], () => translate('ROLE_IS_REQUIRED_FIELD'))
          .required(() => translate('ROLE_IS_REQUIRED_FIELD'))
          .label('Role'),
      });

      const onSubmitUpdate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        if (relatedToHub.value === true) {
          values.hubIds = selectedHubs.value;
        } else {
          values.hubIds = [];
        }

        delete values.verifyPassword;
        values.isActive = true;
        values.mobile = String('964' + values.mobile);
        await store.dispatch(Actions.UPDATE_ADMIN_ACCOUNT, {
          id: admin.value?.id,
          data: values,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_ADMIN'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'admins-listing' });
          });
        }
      };

      return {
        roles: computed(() => store.getters.rolesList),
        selectedHubs,
        hubsTitle,
        relatedToHub,
        onSubmitUpdate,
        adminMobile,
        submitButton,
        translate,
        adminSchema,
        goBack,
        admin,
        can,
      };
    },
  });
